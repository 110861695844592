import createPersistedState from 'vuex-persistedstate'
// import Cookies from 'js-cookie';

export default (context) => {
  return createPersistedState({
    key: 'vuex',
    paths: ['session'],
    storage: {
      getItem: key => {
        if (process.server) {
          // return context.req.cookies[key]
        } // At process.client we will already have loaded from server storage.
      },
      setItem: (key, value) => {
        if (process.client) {
          let parsed_value = JSON.parse(value);
          // console.log('Setting:', parsed_value);

          if (parsed_value.session && parsed_value.session.is_loaded) {
            window.localStorage.setItem(key, value);
          }
          // Cookies.set(key, value, {expires: 365})
        }
      },
      removeItem: key => {
        if (process.client) {
          window.localStorage.removeItem(key);
          // Cookies.remove(key)
        }
      }
    }
  })(context.store)
}

import Vue from 'vue'

// TODO: Workaround to access from store
Vue.postMessageToOrigin = (target_origin, message) => {
	if (process.client && target_origin) {
		window.top.postMessage(message, target_origin);
	}
};

Vue.postAnalyticsMessageToOrigin = (target_origin, action, label, value) => {
	let event = {action};
	if (label !== undefined) event.label = label;
	if (value !== undefined) event.value = value > 0 ? value : 0;
	Vue.postMessageToOrigin(target_origin, {
		kind: 'analytics',
		event
	})
};

Vue.mixin({
	methods: {
		postMessageToOrigin(message) {
			// console.log('Posting message:', this.$route.query.origin, message);
			Vue.postMessageToOrigin(this.$route.query.origin, message);
		},
		postAnalyticsMessageToOrigin(action, label, value) {
			Vue.postAnalyticsMessageToOrigin(this.$route.query.origin, action, label, value)
		}
	}
});

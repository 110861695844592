// import copy from "fast-copy";
// import traverse from "traverse";
// import getTemplatedValue from '@/tools/get_templated_value'

export default () => ({
	namespaced: true,
	state: () => ({
		first_question: null,
		site_name: null,
		site_key: null,
		icon_file: {},
		template: null,
		default_palette: null,
		strings_templates: null,
		technology_key: null,
		technology: {},
		technologies_briefs: {},
		marks: [],
		answers_list_key: null,
		questionnaire_step_sn: null,
		error_code: null,
		modal_opened: null,
		modal_minimised: null,
		modal_info: {},
		article_name: '',
		article: '', // TODO: Separate article storage
		article_score: -1,
		articles_briefs: {}, // Cache
		articles: {}, // Cache
		sentry_dsn: null,
		disqus_site_name: null,
		is_for_printing: false,
		printing_type: ''
	}),
	mutations: {
		_setFirstQuestion(state, question) {
			state.first_question = question;
		},
		_setSiteName(state, site_name) {
			state.site_name = site_name;
		},
		_setSite(state, site) {
			state.marks = [].concat(site.marks);
			state.resources_fields = [].concat(site.resources_fields);
			state.site_key = site.key;
			state.template = site.template;
			state.icon_file = site.icon_file;
			state.dimensions = site.dimensions;
			state.technologies_briefs = site.technologies_briefs;
			state.resources_briefs = site.resources_briefs;
			state.sentry_dsn = site.sentry_dsn;
			state.disqus_site_name = site.disqus_site_name;
			state.send_from_email = site.send_from_email;
			state.recaptcha_site_key = (site.recaptcha && site.recaptcha.site_key) || null;
			state.authors = site.authors;
		},
		_setIsForPrinting(state, is_for_printing) {
			state.is_for_printing = is_for_printing;
		},
		_setPrintingType(state, printing_type) {
			state.printing_type = printing_type;
		},
		_setArticleName(state, article_name) {
			state.article_name = article_name;
		},
		_setArticle(state, article) {
			state.article = article;
		},
		_setArticleNPScore(state, article_npscore) {
			state.article.npscore = article_npscore;
		},
		_setDefaultPalette(state, default_palette) {
			state.default_palette = JSON.parse(JSON.stringify(default_palette));
		},
		_setModalOpened(state, value) {
			value = value || [];

			state.modal_opened = value[0] || '';
			state.modal_info = value[1] || null;
		},
		_setModalMinimised(state, value) {
			value = value || [];

			state.modal_minimised = value[0] || '';
		},
		_setTechnologyKey(state, technology_key) {
			state.technology_key = technology_key;
		},
		_setTechnology(state, technology) {
			state.technology = technology;
		},
		_setArticlesBriefs(state, articles_briefs_mapping) {
			state.articles_briefs = articles_briefs_mapping;
		},
		_cacheArticleBrief(state, [ukey, item]) {
			state.articles_briefs[ukey] = item;
		},
		_cacheArticle(state, [ukey, item]) {
			state.articles[ukey] = item;
		},
		_setAnswersListKey(state, answers_list_key) {
			state.answers_list_key = answers_list_key;
		},
		_setQuestionnaireStepSN(state, questionnaire_step_sn) {
			state.questionnaire_step_sn = questionnaire_step_sn;
		},
		_setErrorCode(state, error_code) {
			state.error_code = error_code;
		}
	},
	actions: {
		async getArticleBriefByName(store, [technology, article_name]) {
			if (store.state.articles_briefs[technology.key + '/' + article_name]) {
				return store.state.articles_briefs[technology.key + '/' + article_name];
			}
			throw new Error('Tried to get article out of cache'); // Shouldn't fire, if cache contains all items
			// let ret = await store.rootState.graph.models.Technology(technology).get_article_brief_by_name(article_name);
			// store.commit('_cacheArticleBrief', [technology.key + '/' + ret.name, ret]);
			// return ret;
		},
		async getArticleByName(store, [technology, article_name]) {
			if (store.state.articles[technology.key + '/' + article_name]) {
				return store.state.articles[technology.key + '/' + article_name];
			}
			let ret = await store.rootState.graph.models.Technology(technology).get_article_by_name(article_name);
			store.commit('_cacheArticle', [technology.key + '/' + ret.name, ret]);
			return ret;
		},
		async voteNPScore(store, [voting_score, voting_comment]) {
			let new_score = await store.rootState.graph.models.Article(store.state.article).create_update_npscore(store.rootState.user.key, voting_score, voting_comment);
			let label = '';
			label += window.location.origin;
			for (let d of store.state.dimensions) {
				if (store.state.technology.dimensions_coordinates[d.question_key]) {
					label += '/' + store.state.technology.dimensions_coordinates[d.question_key];
				}
			}
			label += '/' + store.state.article.name;

			store.dispatch('tracker/trackEvent', {
				analytics: true,
				category: 'nps',
				action: 'vote',
				label: label,
				is_interaction: true,
				value: voting_score
			}, {root: true});

			store.commit('_setArticleNPScore', new_score);
		},
		async nuxtServerInit(store, context) {
			let config = require('../config');

			let site_name = context.req.hostname;
			store.commit('_setSiteName', site_name);
			store.commit('_setSite', context.req.site);
			store.commit('_setIsForPrinting', !!(context.req.query.for_printing));
			store.commit('_setPrintingType', context.req.query.printing_type);

			// console.log('CONFIG:', config, config.CLIENT);
			let palette = config.CLIENT.DASHBOARD.DEFAULT_PALETTES[context.req.site.template];
			store.commit('_setDefaultPalette', palette);

			// let utm_tail = url.parse(context.req.originalUrl).query;
			// if (utm_tail) {
			//   await store.dispatch('session/setUtmTail', utm_tail, {root: true})
			//   console.log('UTM tail:', store.rootState.session.utm_tail);
			// }

			await store.dispatch('updateSiteState', context);
		},
		async updateSiteState(store, context) {
			if (store.rootState.graph.session_init_promise) await store.rootState.graph.session_init_promise;

			let to_store_commit = [];
			let graph = store.rootState.graph;
			let path = [];
			let cur_tech_brief = null;
			let cur_article_name = '';
			for (let dimension_i = 0; dimension_i < graph.config.ARTICLES_DIMENSIONS_COUNT + 1; dimension_i++) {
				let t_path_str = path.join('/');
				let t_tech_brief = Object.values(store.state.technologies_briefs).find(tb => tb.path_str === t_path_str);
				if (t_tech_brief) {
					cur_tech_brief = t_tech_brief;
				}
				// console.log('Tech found:',t_path_str, t_tech_brief);
				let path_part = context.params['pageCoord' + dimension_i];
				// console.log('Adding Path part:', path_part);
				if (!path_part) break;
				path.push(path_part);
			}

			let error_code = null;
			let error_message = null;

			let technology_was_updated = false;
			let technology = store.state.technology;
			if (!technology || cur_tech_brief.key !== technology.key) {
				technology_was_updated = true;
				technology = await graph.models.Site.load_technology(cur_tech_brief.path_str);

				if (!technology) {
					// console.log('Technology not found:', cur_tech_brief.path);
					technology = await graph.models.Site.load_technology([]); // Load General Technology
					error_code = 404;
					error_message = 'Technology not found';
				}

				to_store_commit.push(['_setTechnologyKey', technology.key]);
				to_store_commit.push(['_setTechnology', technology]);

				if (technology && technology.questions && technology.questions[0]) {
					let question = await graph.models.Question.load_it(technology.questions[0]);
					to_store_commit.push(['_setFirstQuestion', question]);
				}

				// TODO: workaround to make site SSR friendly: Load all articles briefs at very beginning.
				to_store_commit.push(['_setArticlesBriefs', await graph.models.Article.get_articles_briefs_mapping()])
			}

			let previous_article_name = store.state.article_name;
			let new_article_name = '';
			if (context.route.meta.find(m => m.is_article_page)) {
				new_article_name = path.slice(cur_tech_brief.path.length).join('/');
				to_store_commit.push(['_setArticleName', path.slice(cur_tech_brief.path.length).join('/')]);
				// console.log(context.route);
				if (new_article_name === '' && context.route.path && context.route.path[context.route.path.length - 1] !== '/') {
					context.redirect(301, context.route.fullPath.replace(context.route.path, context.route.path + '/'));
				}
			}
			to_store_commit.push(['_setArticleName', new_article_name]);
			if (technology_was_updated || previous_article_name !== new_article_name) {
				// console.log('Is updating article:', technology_was_updated, previous_article_name !== new_article_name);
				try {
					to_store_commit.push(['_setArticle', await store.dispatch('getArticleByName', [technology, new_article_name])]);
				}
				catch (err) {
					if (err.status === 404) {
						error_code = 404;
						error_message = 'Article not found: ' + new_article_name;
					} else {
						throw err;
					}
				}
			}

			to_store_commit.push(['_setErrorCode', error_code]);

			// Writing to session state - anywhere, reading - here
			let session_state = store.rootState.session.states_by_technology[technology.key] || {};

			let answers_list_key = context.params.answersListKey || session_state.answers_list_key || null;
			to_store_commit.push(['_setAnswersListKey', answers_list_key]);

			let questionnaire_step_sn = context.params.stepSn;
			if (questionnaire_step_sn === undefined) questionnaire_step_sn = session_state.step_sn;
			if (questionnaire_step_sn === undefined) questionnaire_step_sn = null;
			if (questionnaire_step_sn) questionnaire_step_sn = parseInt(questionnaire_step_sn);

			if (context.route.meta.find(m => m.is_questionnaire_report_page) !== undefined) questionnaire_step_sn = -1;
			to_store_commit.push(['_setQuestionnaireStepSN', questionnaire_step_sn]);

			// Grouping all store commits to minimize delta in loading styles & content times.
			for (let to_commit_item of to_store_commit) {
				store.commit(to_commit_item[0], to_commit_item[1]);
			}
			if (error_code) {
				return context.error({statusCode: error_code, message: error_message})
			}
			await store.dispatch('session/setTechnologyState', [technology.key, answers_list_key, questionnaire_step_sn], {root: true})
		}
	},
});

let ret = {};
let default_env = {};
let default_env_text = '';

function get_env(key, default_value) {
	if (!default_env.hasOwnProperty(key)) {
		default_env[key] = default_value;
		if (default_env_text !== '') default_env_text += '\n';
		default_env_text += key + '=' + default_value;
	}
	if (process.env.hasOwnProperty(key)) {
		return process.env[key];
	}
	return default_value;
}

ret.IS_LOCAL = get_env('IS_LOCAL', 'TRUE') === 'TRUE';
ret.IS_DEBUG = get_env('IS_DEBUG', 'TRUE') === 'TRUE';
ret.IS_PRODUCTION = get_env('IS_PRODUCTION', 'FALSE') === 'TRUE';
ret.IS_SSL = get_env('IS_SSL', 'FALSE') === 'TRUE';
ret.USE_COUNTERS = get_env('USE_COUNTERS', 'TRUE') === 'TRUE';

// ENVIRONMENT IDENTIFICATION
ret.RELEASE_VERSION = 'pfh@' + get_env('ENVIRONMENT_NAME', 'UNKNOWN') + '-' + (parseInt(get_env('HEROKU_RELEASE_VERSION', 'v0').substr(1)));
ret.UPCOMING_RELEASE_VERSION = 'pfh@' + get_env('ENVIRONMENT_NAME', 'UNKNOWN') + '-' + (parseInt(get_env('HEROKU_RELEASE_VERSION', 'v0').substr(1)) + 1);
ret.ENVIRONMENT_NAME = get_env('ENVIRONMENT_NAME', 'UNKNOWN');
ret.DASHBOARD_DOMAIN = get_env('DASHBOARD_DOMAIN', 'quizsitebuilder.com'); // For Dynamic DNS
ret.APP_NAME = get_env('HEROKU_APP_NAME', 'N/A');
ret.GIT_BRANCH = get_env('HEROKU_BRANCH', 'N/A');

// {
//   "HEROKU_APP_NAME": "platform-for-863-adc-ne-yopv10",
//   "HEROKU_BRANCH": "863-adc-next-button-text-toggle-skip",
//   "IS_PREVIEW_APP": "TRUE"
// }


// HTTP
ret.HTTP = {};
ret.HTTP.PORT = parseInt(get_env('PORT', ''));

// ADMINISTRATIVE
ret.AUTH_ADMIN_PASSWORD = get_env('AUTH_ADMIN_PASSWORD', 'PfhA2018');
ret.AUTH_STAFF_PASSWORD = get_env('AUTH_STAFF_PASSWORD', 'Pfh2018');
ret.AUTH_SALES_PASSWORD = get_env('AUTH_SALES_PASSWORD', 'Pfh2020');
ret.AUTH_EXPORT_PASSWORD = get_env('AUTH_EXPORT_PASSWORD', 'PfhExp2020');
ret.ADMIN_EMAIL = get_env('ADMIN_EMAIL', 'unknown');
ret.REGULAR_STATS_RECEPIENTS_EMAILS = get_env('REGULAR_STATS_RECEPIENTS_EMAILS', 'unknown');
ret.BASIC_AUTH_ENABLED = get_env('BASIC_AUTH_ENABLED', 'TRUE') === 'TRUE';
ret.PASSWORD_SALT_ROUNDS = parseInt(get_env('PASSWORD_SALT_ROUNDS', '10'));
ret.JWT_PRIVATE_KEY = get_env('JWT_PRIVATE_KEY', 'JWT_PRIVATE_KEY_SECRET');
ret.JWT_EXPIRATION = get_env('JWT_EXPIRATION', '30m');
ret.JWT_EXPIRATION_REMEMBER_ME = get_env('JWT_EXPIRATION_REMEMBER_ME', '30d');
ret.EMAIL_FROM = get_env('EMAIL_FROM', 'local@developersforhire.com');
ret.JIRA_EMAIL_SENDER = get_env('JIRA_EMAIL_SENDER', '');
ret.JIRA_EMAIL_RECEPIENT = get_env('JIRA_EMAIL_RECEPIENT', 'jira@talentsites.atlassian.net');
ret.ARTICLES_DIMENSIONS_COUNT = parseInt(get_env('ARTICLES_DIMENSIONS_COUNT', '2'));

// SIDE-SERVICES
ret.SIDE_SERVICES_ROOT_URL = get_env('SIDE_SERVICES_ROOT_URL', 'unknown');
ret.SIDE_SERVICES_AUTH_NAME = get_env('SIDE_SERVICES_AUTH_NAME', 'unknown');
ret.SIDE_SERVICES_AUTH_PASSWORD = get_env('SIDE_SERVICES_AUTH_PASSWORD', 'unknown');

// SENTRY
ret.SENTRY = {};
ret.SENTRY.DSN = get_env('SENTRY_DSN', '');
ret.SENTRY.RELEASE_VERSION = ret.RELEASE_VERSION;
ret.SENTRY.ENVIRONMENT_NAME = ret.ENVIRONMENT_NAME; // CLIENT SENTRY DUPLICATE FOR FLEXIBILITY
ret.SENTRY.AUTH_TOKEN = get_env('SENTRY_AUTH_TOKEN', '');
ret.SENTRY.ORG = get_env('SENTRY_ORG', 'platform-for-hire');
ret.SENTRY.PROJECT = get_env('SENTRY_PROJECT', 'platform-for-hire');
ret.SENTRY.LOG_LEVEL = get_env('SENTRY_LOG_LEVEL', 'warn');
ret.SENTRY.NO_PROGRESS_BAR = get_env('SENTRY_NO_PROGRESS_BAR', '0');

// EMAILING
ret.SMTP_PASSWORD = get_env('SMTP_PASSWORD', 'SMTP_PASSWORD');
ret.SMTP_USERNAME = get_env('SMTP_USERNAME', 'SMTP_USERNAME');
ret.SMTP_SERVER = get_env('SMTP_SERVER', '');
ret.SMTP_PORT = parseInt(get_env('SMTP_PORT', '587'));

// MAILCHIMP
ret.MAILCHIMP_API_KEY = get_env('MAILCHIMP_API_KEY', 'N/A');

// PDF GENERATION
ret.PDF = {};
ret.PDF.USERNAME = get_env('PDF_USERNAME', '');
ret.PDF.API_KEY = get_env('PDF_API_KEY', '');

// DATABASES
ret.DB = {};

// BUCKETEER AWS S3 FILES STORAGE
ret.BUCKETEER_AWS_ACCESS_KEY_ID = get_env('BUCKETEER_AWS_ACCESS_KEY_ID', '');
ret.BUCKETEER_AWS_REGION = get_env('BUCKETEER_AWS_REGION', '');
ret.BUCKETEER_AWS_SECRET_ACCESS_KEY = get_env('BUCKETEER_AWS_SECRET_ACCESS_KEY', '');
ret.BUCKETEER_BUCKET_NAME = get_env('BUCKETEER_BUCKET_NAME', '');

// MONGOOSE
ret.DB.MONGOOSE = {};
ret.DB.MONGOOSE.URI = get_env('DB_MONGOOSE_URI', 'mongodb://localhost/platform-for-hire');
ret.DB.MONGOOSE.POOL_SIZE = parseInt(get_env('DB_MONGOOSE_POOL_SIZE', '3'));

// PRODUCTION DATABASES
ret.PRODUCTION_DB = {};
ret.PRODUCTION_DB.TITLE = get_env('PRODUCTION_DB_TITLE', 'N/A');

// MONGOOSE
ret.PRODUCTION_DB.MONGOOSE = {};
ret.PRODUCTION_DB.MONGOOSE.URI = get_env('PRODUCTION_DB_MONGOOSE_URI', '');
ret.PRODUCTION_DB.MONGOOSE.POOL_SIZE = parseInt(get_env('DB_MONGOOSE_POOL_SIZE', '1'));

// GOOGLE TABLES IMPORT SOURCES // TODO: remove from here, make admin-only dashboard for sites management
ret.GOOGLE_TABLES = {};
ret.GOOGLE_TABLES.SITES_CSV_URL = get_env('GOOGLE_TABLES_SITES_CSV_URL', '');

// APPLICATION CLIENT
ret.CLIENT = {};
ret.CLIENT.ARTICLES_DIMENSIONS_COUNT = ret.ARTICLES_DIMENSIONS_COUNT;
ret.CLIENT.ENVIRONMENT_NAME = ret.ENVIRONMENT_NAME;
ret.CLIENT.USE_COUNTERS = ret.USE_COUNTERS;
ret.CLIENT.DASHBOARD = {};
ret.CLIENT.DASHBOARD.SUPPORTED_RESOURCES_KINDS = ['platform', 'article', 'company', 'file', 'faq'];
ret.CLIENT.DASHBOARD.SUPPORTED_LAYOUT_TEMPLATES = ['appdevcost', 'developers', 'designers', 'recruitment', 'bsa', 'readiness', 'tt-readiness'];
ret.CLIENT.DASHBOARD.DEFAULT_PALETTES = {
	appdevcost: {},
	developers: {
		'bg': '#eee',
		'fg': '#fff',
		'clr-prime-light': '#F7C65F',
		'clr-prime': '#FDAC00',
		'clr-prime-dark': '#F39800',
		'clr-prime-alt': '#fff',
		'clr-svg-prime': '#F7C65F',
		'clr-svg-prime-light': '#F3D698',
		'clr-texts': '#3d4842'
	},
	bsa: {},
	readiness: {},
	"tt-readiness": {},
	designers: {
		'bg': '#f7f7f7',
		'fg': '#fff',
		'clr-texts': '#111111',

		'clr-prime-lighten': '#7fd7fc', // TODO: remove ??
		'clr-prime-light': '#26bafa',
		'clr-prime': '#00affa',
		'clr-prime-dark': '#009bf7',
		'clr-prime-darker': '#023061',

		'clr-alt-light': '#f64647',
		'clr-alt': '#f96566',
		'clr-alt-dark': '#ec5d5e',

		'clr-ops': '#023061', // TODO: Make more readable name
		'clr-neg': '#023061', // TODO: Make more readable name
		'clr-highlight': '#fff',
	},
	recruitment: {
		'bg': '#eee',
		'fg': '#fff',
		'clr-prime-light': '#f5f5f5',
		'clr-prime': '#fcfcfc',
		'clr-prime-dark': '#f2f2f2',
		'clr-prime-alt': '#fff',
		'clr-svg-prime': '#f5f5f5',
		'clr-svg-prime-light': '#f2f2f2',
		'clr-texts': '#474747'
	},
	example_layout: {
		'bg': '#eee',
		'fg': '#fff',
		'clr-prime-light': '#f5f5f5',
		'clr-prime': '#fcfcfc',
		'clr-prime-dark': '#f2f2f2',
		'clr-prime-alt': '#fff',
		'clr-svg-prime': '#f5f5f5',
		'clr-svg-prime-light': '#f2f2f2',
		'clr-texts': '#474747'
	},
	example_estimator_layout: {
		'bg': '#eee',
		'fg': '#fff',
		'clr-prime-light': '#f5f5f5',
		'clr-prime': '#fcfcfc',
		'clr-prime-dark': '#f2f2f2',
		'clr-prime-alt': '#fff',
		'clr-svg-prime': '#f5f5f5',
		'clr-svg-prime-light': '#f2f2f2',
		'clr-texts': '#474747'
	},
	example_dimensions_layout: {
		'bg': '#eee',
		'fg': '#fff',
		'clr-prime-light': '#f5f5f5',
		'clr-prime': '#fcfcfc',
		'clr-prime-dark': '#f2f2f2',
		'clr-prime-alt': '#fff',
		'clr-svg-prime': '#f5f5f5',
		'clr-svg-prime-light': '#f2f2f2',
		'clr-texts': '#474747'
	},
};

ret.CLIENT.DASHBOARD.CROPS_SIZES = {
	// Example: [template] -> [crops_size_name] -> {xs: {ratio: 4/3, max_width: 350}}
	// where:
	// ratio - width divided to height cropping aspect ratio.
	// max_width - the width that is needed at largest screens to see image well
	// (pyramid is generated till this width, also image quality warning is shown if cropping is not reach this width)
	developers: {
		article_picture: {
			desktop: {ratio: 655 / 264, max_width: 1310},
			mobile: {ratio: 311 / 129, max_width: 622},
			small_preview: {ratio: 262 / 136, max_width: 524},
			large_preview: {ratio: 374 / 194, max_width: 748},
			card_preview: {ratio: 290 / 130, max_width: 580},
			mini_full_size: {ratio: 320 / 140, max_width: 1200},
		},
		article_illustration: {
			horizontal_desktop: {ratio: 1144 / 326, max_width: 2288},
			horizontal_tablet: {ratio: 744 / 335, max_width: 1488},
			horizontal_mobile: {ratio: 369 / 335, max_width: 738},
		}
	},
	designers: {
		article_picture: {
			desktop: {ratio: 695 / 500, max_width: 1390},
			tablet: {ratio: 718 / 516, max_width: 1436},
			mobile: {ratio: 375 / 270, max_width: 750},
			small_preview: {ratio: 180 / 130, max_width: 360},
			slider_preview: {ratio: 315 / 130, max_width: 630},
			sitemap_preview: {ratio: 235 / 65, max_width: 470},
			sitemap_preview_mobile: {ratio: 315 / 65, max_width: 630},
			card_preview: {ratio: 410 / 295, max_width: 820},
			card_preview_small: {ratio: 325 / 234, max_width: 650},
			card_preview_small_blog_first: {ratio: 375 / 270, max_width: 750},
			mini_full_size: {ratio: 320 / 140, max_width: 1200},
		},
		article_illustration: {
			horizontal_desktop: {ratio: 1144 / 326, max_width: 2288},
			horizontal_tablet: {ratio: 744 / 335, max_width: 1488},
			horizontal_mobile: {ratio: 369 / 335, max_width: 738},
		}
	},
	bsa: {
		question_answer_background: {
			full: {ratio: 1135 / 601, max_width: 2270} // TODO: John, please, correct values according to the design
		},
		block_section_hero_logo: {
			square: {ratio: 1, max_width: 200}
		},
		block_home_hero_bg: {
			full: {ratio: 1135 / 601, max_width: 2270}
		},
		article_picture: {
			preview: {ratio: 289 / 133, max_width: 574},
			card_preview_mobile: {ratio: 335 / 190, max_width: 670},
			card_preview_desktop: {ratio: 350 / 190, max_width: 700},
			tile_vertical: {ratio: 540 / 600, max_width: 1080 },
			tile_horizontal: {ratio: 540 / 290, max_width: 1080 },
			tile_mobile: {ratio: 343 / 439, max_width: 686}
		},
		article_hero_bg: {
			full: {ratio: 1135 / 601, max_width: 2270}
		},
		article_illustration: {
			horizontal_desktop: {ratio: 695 / 161, max_width: 1390},
			horizontal_mobile: {ratio: 335 / 211, max_width: 670},
		}
	},
	recruitment: {
		block_article_info_picture: {
			mobile: {ratio: 288 / 187, max_width: 576},
			desktop: {ratio: 724 / 470, max_width: 1448},
		},
		block_article_picture: {
			mobile: {ratio: 288 / 187, max_width: 1152},
			desktop: {ratio: 724 / 470, max_width: 1448},
		},
		block_article_quote_avatar: {
			square: {ratio: 1, max_width: 500}
		},
		article_picture: {
			mini_full_size: {ratio: 320 / 140, max_width: 1200},
			full_size: {ratio: 2, max_width: 2800},
			preview: {ratio: 3 / 2, max_width: 1280},
			preview_wide: {ratio: 2, max_width: 1400},
		},
		author_picture: {
			square: {ratio: 1, max_width: 1024},
		},
		resource_cover_picture: {
			rect: {ratio: 100 / 70, max_width: 1024},
		},
	},
	appdevcost: {
		article_picture: {
			mini_full_size: {ratio: 320 / 140, max_width: 1200},
			card_preview: {ratio: 290 / 180, max_width: 580},
		},
		block_article_picture: {
			mobile: {ratio: 288 / 187, max_width: 1152},
			desktop: {ratio: 724 / 470, max_width: 1448},
		},
		block_article_banner: {
			mobile: {ratio: 375 / 305, max_width: 750},
			desktop: {ratio: 1440 / 392, max_width: 2880},
		},
	}
};

ret.CLIENT.IS_LOCAL = ret.IS_LOCAL;
ret.CLIENT.MODELS_URI_PATH = get_env('CLIENT_MODELS_URI_PATH', '/models_api/{{model_name}}/{{call_type}}/{{method}}');
ret.CLIENT.ATTRIBUTION_TRACKER_URI = get_env('CLIENT_ATTRIBUTION_TRACKER_URI', '');
ret.CLIENT.ENVIRONMENT_NAME = ret.ENVIRONMENT_NAME;
ret.CLIENT.SENTRY = {
	RELEASE_VERSION: ret.SENTRY.RELEASE_VERSION,
	ENVIRONMENT_NAME: ret.SENTRY.ENVIRONMENT_NAME // CLIENT SENTRY DUPLICATE FOR FLEXIBILITY
};
ret.CLIENT.PRODUCTION_DB_TITLE = ret.PRODUCTION_DB.TITLE;

// Writing structure and default values to ./.env_example file
if (process.server !== false) {
	const fs = require('fs');
	fs.writeFileSync('./.env_example', default_env_text, 'utf8');
}

module.exports = ret;

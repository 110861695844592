import Vue from 'vue';
import Cookie from 'js-cookie'

export default () => ({
  namespaced: true,
  state: () => ({
    auth: {user: null, token: null},
    config: null,
    models: null,
    session_init_promise: null
  }),
  mutations: {
    _authenticate(state, auth_info) {
      state.auth.user = auth_info.user;
      state.auth.token = auth_info.token;
      // console.log('Authenticating..', auth_info.token);
      Cookie.set('auth_token', auth_info.token || '', {expires: 7});
    },
    _updateConfig(state, config) {
      state.config = JSON.parse(JSON.stringify(config));
    },
    _initModels(state, models) {
      state.models = models;
    },
    _setSessionInitPromise(state, session_init_promise) {
      state.session_init_promise = session_init_promise;
    },
  },
  actions: {
    authenticate(store, auth_info) {
      if (!auth_info) {
        auth_info = {user: null, token: null};
      }
      if (!auth_info.user) {
        auth_info.user = {_id: null};
      }
      store.commit('_authenticate', auth_info);
    },
    removeAuthentication(store) {
      store.commit('_authenticate', {user: {_id: null}, token: null});
    },
    rotateAuthentication(store, token) {
      store.commit('_authenticate', {user: store.state.auth.user, token: token});
    },
    setSessionInitPromise: (store, session_init_promise) => {
      if (process.client) {
        store.commit('_setSessionInitPromise', session_init_promise);
      }
    },
    async nuxtServerInit(store, context) {
      const full_config = require('../config');
      // console.log('Were Inited:', !!this.req);
      this.req = context.req;

      // console.log('Inited:', this);

      let config = Object.assign({}, full_config.CLIENT);

      let models = {};
      config.MODELS = models;
      for (let model_name in process.graph.models) {
        let model = process.graph.models[model_name];
        models[model_name] = {methods: {}, static_methods: {}};

        for (let field_name in model) {
          let field = model[field_name];
          if (!field || !field.perms || !field.perms.length) continue;
          models[model_name].static_methods[field_name] = {};
          if (field.is_stream_result) {
            models[model_name].static_methods[field_name].is_stream_result = true;
          }
          if (field.is_retryable) {
            models[model_name].static_methods[field_name].is_retryable = true;
          }
        }
        for (let field_name in model.prototype) {
          try {
            let field = model.prototype[field_name];
            if (!field || !field.perms || !field.perms.length) continue;
            models[model_name].methods[field_name] = {};
            if (field.is_stream_result) {
              models[model_name].methods[field_name].is_stream_result = true;
            }
            if (field.is_retryable) {
              models[model_name].methods[field_name].is_retryable = true;
            }
          }
          catch (e) { // TODO: optimize prototype methods obtaining
          }
        }
      }

      let dashboard_config = config.DASHBOARD;
      delete config.DASHBOARD;
      store.commit('_updateConfig', config);

      await store.dispatch('init');

      // console.log('Request Cookies:', context.req.cookies);
      if (context.req.cookies && context.req.cookies.auth_token) {
        return context.req.app.graph.models.User.jwt_login(context.req.cookies.auth_token, context.req)
          .then(auth_info => {
            auth_info = JSON.parse(JSON.stringify(auth_info)); // Converting to plain object
            auth_info.user.model_name = 'User';

            config.DASHBOARD = dashboard_config;
            store.commit('_updateConfig', config);
            // console.log('Logging in: ', auth_info, auth_info.toObject())
            return store.dispatch('authenticate', auth_info);
          })
          .catch(err => {
            // console.log('Clearing cookie, because of error:', err);
            context.res.clearCookie('auth_token');
          })
      } else {
        store.dispatch('removeAuthentication')
      }
    },
    init(store) {
      const config = store.state.config;
      let create_model_pair = function (model_name) {
        let ModelConstructor = function () {
          this._model_name = model_name; // TODO: remove
        };
        let model = function (obj) {
          let ret = new ModelConstructor();
          ret._obj = obj;
          return ret;
        };
        return [model, ModelConstructor];
      };
      let register_methods = function (model, model_constructor) {
        for (let k in model.prototype) {
          model_constructor.prototype[k] = model.prototype[k];
        }
      };
      let create_model_method = function (model_name, method_name, method_info) {
        return function () {
          let args = Array.prototype.slice.call(arguments);
          return store.dispatch('queryModelAPI', [model_name, this._obj, 'instance', method_name, args, method_info]);
        };
      };
      let create_model_static_method = function (model_name, method_name, method_info) {
        return function () {
          let args = Array.prototype.slice.call(arguments);
          return store.dispatch('queryModelAPI', [model_name, null, 'static', method_name, args, method_info]);
        }
      };
      let models = {};
      for (let model_name in config.MODELS) {
        let model_info = config.MODELS[model_name];
        let model_pair = create_model_pair(model_name);
        let model = model_pair[0];
        let model_constructor = model_pair[1];
        let method_name;
        for (method_name in model_info.methods) {
          model.prototype[method_name] = create_model_method(model_name, method_name, model_info.methods[method_name]);
        }
        for (method_name in model_info.static_methods) {
          model[method_name] = create_model_static_method(model_name, method_name, model_info.static_methods[method_name]);
        }
        register_methods(model, model_constructor);
        models[model_name] = model;
      }

      store.commit('_initModels', models);
    },
    async login(store, [email, password, remember_me]) {
      try {
        let auth_info = await store.state.models.User.jwt_get_token_by_password(email, password, remember_me);
        await store.dispatch('authenticate', auth_info);
        return {result: 'ok'}
      } catch (err) {
        return {result: 'failed'}
      }
    },
    async queryModelAPI(store, [model_name, instance, call_type, method, params]) {
      if (process.client && !(model_name === 'User' && method === 'account_user')) {
        if (!store.state.session_init_promise) throw new Error(`Trying to access Graph API before session init (User creation). ${call_type}: ${model_name}.${method}()`);
        if (!store.rootState.user) {
          throw new Error('User is not available. Session was not ready on call.');
        }
        await store.state.session_init_promise;
      }
      if (process && process.server) {
        // console.log('Querying API at server side:', model_name + '.' + method + ' (' + call_type + ') ' + params.toString());
        return new Promise((resolve, reject) => { // TODO: be careful, mixing callbacks and promises
          process.processAPICall( // Emulating Express call, avoiding express network stack delays
            // req
            Object.assign(this.req, {
              body: {
                params,
                target_id: instance && instance._id || null,
                instance
              },
              params: {
                model_name,
                call_type,
                method
              },
            }),
            // resp
            {
              send: function (result) {
                // console.log('Resolving API call result:', JSON.stringify(result).substr(0, 100));
                return resolve(JSON.parse(JSON.stringify(result))) // Converting to plain object
              }
            },
            // next
            (err) => {
              // console.log('API Call Rejected:', model_name + '.' + method + ' (' + call_type + ') ' + params.toString(), err);
              reject(err)
            }
          )
        });
      }
      // console.log('Querying API at client side:', model_name + '.' + method + ' (' + call_type + ') ' + params.toString());
      let instance_id = null;
      if (instance) instance_id = instance._id || instance;

      let data = {
        'params': params !== undefined ? params : [],
        'target_id': (call_type === 'instance') ? instance_id : '',
        'instance': (call_type === 'instance' && instance_id) ? instance : null
      };
      let url = store.state.config.MODELS_URI_PATH
        .replace('{{model_name}}', model_name)
        .replace('{{call_type}}', call_type)
        .replace('{{method}}', method);

      let headers = {};
      headers['Content-Type'] = 'multipart/form-data';
      let form_data = new FormData();

      let files = [];
      for (let param_i in data.params) {
        let param = data.params[param_i];
        if (param instanceof File) {
          files.push(param);
          form_data.append('FILE_' + (files.length - 1), param);
          data.params[param_i] = 'FILE_' + (files.length - 1);
        }
      }
      // console.log(data.params);
      form_data.append('params', JSON.stringify(data.params));
      // console.log('Target ID:', data.target_id, JSON.stringify(data.target_id))
      form_data.append('target_id', data.target_id);
      // console.log(data.instance);
      form_data.append('instance', JSON.stringify(data.instance));

      if (store.state.auth.token) headers['x-access-token'] = store.state.auth.token;
      return this.$axios.post(url, form_data, {headers})
        .then(ret => {
          if (ret.headers['x-access-token-rotated']) {
            store.dispatch('rotateAuthentication', ret.headers['x-access-token-rotated']);
          }
          if (ret.data === '' || ret.data === undefined) {
            return Promise.reject(new Error('Empty response'));
          }
          if (ret.data.result === 'error') {
            if (ret.data.error_name === 'TokenExpiredError') {
              window.alert('Sorry, session time is out. Please, login again.');
              window.location.reload();
            }
            return Promise.reject(new Error('Error response:' + JSON.stringify(ret.data)));
          }
          return ret.data;
        })
        .catch(e => {
          if (!process.server) {
            if (e.response && e.response.status === 403) {
              window.alert('Sorry, you don\'t have enough permissions to perform the action.');
              // window.location.replace('/dashboard/login');
              window.location.reload();
              return Promise.reject(e);
            }
            window.alert('Action failed: ' + ((e && e.response && e.response.headers && e.response.headers['err-message']) || e) + '. Please, try again.');
            return Promise.reject(e);
          } else {
            console.log('Error:', e);
          }
        });
    }
  },

});

<!-- Should be one line to exclude spaces around -->
<template><a :href="makeURL(location)" @click.prevent="processClick" @contextmenu="accountClick" :rel="should_robot_follow ? undefined : 'nofollow'"><slot></slot></a></template>
<script>
	export default {
		props: {
			location: {
				type: Object,
				required: true
			},
			should_robot_follow: {
				type: Boolean,
				required: false,
				default: false
			},
			target: {
				type: String,
				required: false,
				default: ''
			},
			account_stats: {
				type: Object,
				required: false
			},
		},
		methods: {
			async accountClick() {
				// console.log('Account stats:', this.account_stats);
				if (this.account_stats) {
					await this.$store.dispatch('tracker/trackEvent', this.account_stats)
				}
			},
			async processClick() {
				await this.accountClick();
				// this.goToURL(this.location);
				if (this.target === '_blank') {
					window.open(this.makeURL(this.location))
				}
				else {
					this.goToURL(this.location);
				}
			},
		},
	}
</script>

import Vue from 'vue';
import PfhLink from '@/components/pfh-link';
import PfhOutsideLink from '@/components/pfh-outside-link';

Vue.mixin({
  components: {
    PfhLink,
    PfhOutsideLink
  },
  methods: {
    makeURL: function (location) {
      let technology_key = (location.params && location.params.technology_key) || this.$store.state.site.technology_key;
      if (!this.$store.state.site.technologies_briefs[technology_key]) technology_key = 'general';
      let path = this.$store.state.site.technologies_briefs[technology_key].path;

      if (location.params && location.params.article_name) {
        path = path.concat([location.params.article_name]);
      }

      let route_name_prefix = 'd' + path.length;

      let route_params = {};
      for (let i = 0; i < path.length; i++) {
        route_params['pageCoord' + i] = path[i];
      }

      let ret = this.$router.resolve({
        name: route_name_prefix + location.name,
        params: Object.assign(route_params, location.params || {}),
        query: location.query || {},
      }).href;

      if (location.params && location.params.article_name) {
        ret = ret.replace(location.params.article_name + '/', location.params.article_name);
      }

      if (location.params && location.params.hash) {
      	ret += '#' + location.params.hash;
      }
      return ret;
    },

    goToURL: async function (location) {
      return this.$router.push(this.makeURL(location));
    },
    substituteURL: async function (location) {
      return this.$router.replace(this.makeURL(location));
    }
  }
});

import Vue from 'vue';

Vue.mixin({
	computed: {
		empty_img_uri() {
			return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
		}
	},
	methods: {
		processHTML: function (value) {
			if (!value) return '';

			return value.replace(/<a .*?>/gm, (match) => {
				if (match.indexOf('target="_blank"') !== -1) {
					if (!/rel=".*?"/g.test(match)) {
						match = match.replace(/>/gm, ' rel="noopener">');
					}
					else if (match.indexOf('noopener') === -1) {
						match = match.replace(/rel="(.*?)"/gm, 'rel="$1 noopener"')
					}
				}
				return ' '+ match;
			})
		},
		processHTMLclean: function (value) {
			value = this.processHTML(value);
			value = value.replace(/<\/(?:p|div)>[^A-z]*?<(?:p|div).*?>/g, '<br>');
			value = value.replace(/<\/?(?:p|div).*?>/g, '');

			return value;
		},
		tryPreloadedImgBase64Uri: function (file_url) {
			return this.$store.state.site.article.preloaded_files[file_url] || file_url || '';
		}
	}
});


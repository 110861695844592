import Vue from 'vue';

export default () => ({
	namespaced: true,
	state: () => ({
		name: '',
		start_loading_progress: 0.0,
		report_loading_progress: 0.0,
		answers_list: null,
		step_sn: -1,
		steps: [],
		step_answer_satisfied: false,
		phase: 'loading-answers', // loading-answers / steps / preparing-report / report
		report: null,

		// DEPRECATED, will be removed in next version
		progress: 0.0,
		question: null,
		preloaded_question: null,
		preloaded_question_promise: null,
		report_info: null,

	}),
	mutations: {
		_setStartLoadingProgress(state, progress) {
			state.start_loading_progress = progress;
		},
		_setReportLoadingProgress(state, progress) {
			state.report_loading_progress = progress;
		},
		_setAnswersList(state, answers_list) {
			state.answers_list = answers_list;
		},
		_updateAnswer(state, [question_key, answer]) {
			Vue.set(state.answers_list.chosen_variants, question_key, answer);
			state.answers_list.last_chosen_variant = question_key;
		},
		_setPhase(state, phase) {
			state.phase = phase;
		},
		_setQuizName(state, quiz_name) {
			state.name = quiz_name;
		},
		_setStepSn(state, step_sn) {
			state.step_sn = step_sn;
		},
		_setSteps(state, steps) {
			state.steps = steps;
		},
		_setStepsAnswerSatisfied(state, step_answer_satisfied) {
			state.step_answer_satisfied = step_answer_satisfied;
		},
		_setReport(state, report) {
			state.report = report;
		},
		// TODO: DEPRECATED, will be removed in next PFH version
		_setProgress(state, progress) {
			state.progress = progress;
		},
		_setQuestion(state, question) {
			state.question = question;
		},
		_setPreloadedQuestionPromise(state, preloaded_question_promise) {
			state.preloaded_question_promise = preloaded_question_promise;
		},
		_setReportInfo(state, report_info) {
			state.report_info = report_info;
		},
	},
	actions: {
		// Create new Answers list for quiz
		async startNewAnswersList(store, [user, technology_key, article_key]) {
			let answers_list = await store.rootState.graph.models.User(user).create_answers_list(technology_key, article_key);
			store.dispatch('tracker/trackEvent', {answers_lists_keys: [answers_list.key]}, {root: true});

			store.dispatch('tracker/trackEvent', {
				analytics: true,
				category: 'quiz.' + (article_key || technology_key),
				action: 'start',
				label: article_key ? 'Article Quiz' : 'Technology Quiz',
				is_interaction: true
			}, {root: true});

			store.commit('_setAnswersList', answers_list);
		},

		// Quiz must load Answers list to be able to show current status and apply new answers
		async loadAnswersList(store, key) {
			let site = store.rootState.site;
			let answers_list = await store.rootState.graph.models.AnswersList.load_it(site.technology_key, key);
			store.commit('_setAnswersList', answers_list);
		},

		// Set Phase
		async setPhase(store, phase) {
			store.commit('_setPhase', phase);
		},

		// Set Quiz Name
		async setQuizName(store, quiz_name) {
			store.commit('_setQuizName', quiz_name);
		},

		// Start/Loading Answers List Progress (just for display)
		async setStartLoadingProgress(store, progress) {
			store.commit('_setStartLoadingProgress', progress);
		},

		// Report Loading Progress (just for display)
		async setReportLoadingProgress(store, progress) {
			store.commit('_setReportLoadingProgress', progress);
		},

		// Set Step SN
		async setStepSn(store, step_sn) {
			store.commit('_setStepSn', step_sn);
		},

		// Set Steps
		async setSteps(store, steps) {
			store.commit('_setSteps', steps);
		},

		// Set Step Answer Satisfied (for required questions)
		async setStepAnswerSatisfied(store, step_answer_satisfied) {
			store.commit('_setStepsAnswerSatisfied', step_answer_satisfied);
		},

		async setAnswer(store, [question_key, answer]) {
			// let question_key = store.state.steps[store.state.step_sn].key;
			store.commit('_updateAnswer', [question_key, answer]);
			await store.rootState.graph.models.AnswersList(store.state.answers_list).save_it();
		},

		async getReport(store) {
			await store.commit('_setReport', null);
			let report = await store.rootState.graph.models.AnswersList(store.state.answers_list).get_article_quiz_report();
			await store.commit('_setReport', report);
			return report;
		},

		async sendAnswerStats(store) {
			let step_sn = store.state.step_sn;
			if (step_sn < 0) return false;

			let step = store.state.steps[step_sn];
			let question = step.blocks[0];
			if (!step) return false;

			let answer = store.state.answers_list.chosen_variants[step.key];
			if (answer === undefined) return false;

			Vue.postAnalyticsMessageToOrigin(
				this.$router.currentRoute.query.origin,
				`quiz_${store.state.name}_question_passed_${step.key}`,
				`${step.key}: Question passed ${(question.question || question.title || `Untitled Question`)}`
			);
			store.dispatch('tracker/trackEvent', {
				analytics: true,
				category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
				action: 'question_passed',
				label: step.key,
				is_interaction: true
			}, {root: true});

			let name = `quiz_${store.state.name}_answer_chosen_${step.key}`;
			if (Array.isArray(answer) && answer.length === 0) {
				Vue.postAnalyticsMessageToOrigin( // TODO: bind analytics to the answer, like we did with attributes, including value sending
					this.$router.currentRoute.query.origin,
					name,
					`${step.key}: Nothing selected`
				);
				store.dispatch('tracker/trackEvent', {
					analytics: true,
					category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
					action: name,
					label: `${step.key}: Nothing selected`,
					is_interaction: true
				}, {root: true});
			}
			for (let answ of Array.isArray(answer) ? answer : [answer]) {
				let label = `${step.key}: ${(answ && (answ.title || (answ.attributes && answ.attributes.title) || answ.name || 'Untitled Answer')) || 'Nothing selected'}`;
				Vue.postAnalyticsMessageToOrigin( // TODO: bind analytics to the answer, like we did with attributes, including value sending
					this.$router.currentRoute.query.origin,
					name,
					label
				);
				store.dispatch('tracker/trackEvent', {
					analytics: true,
					category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
					action: name,
					label: label,
					is_interaction: true
				}, {root: true});
			}
		},

		async goToStep(store, step_sn) {
			await store.dispatch('sendAnswerStats');
			this.$router.push({
				query: Object.assign({}, this.$router.currentRoute.query, {
					qid: store.state.answers_list.key,
					step: step_sn
				})
			})
		},

		async goToReport(store) {
			await store.dispatch('sendAnswerStats');
			this.$router.push({
				query: Object.assign({}, this.$router.currentRoute.query, {
					qid: store.state.answers_list.key,
					report: 1
				})
			})
		},

		async goToStartAgain(store) {
			store.commit('_setAnswersList', null);
			this.$router.push({
				query: Object.assign({}, this.$router.currentRoute.query, {
					qid: store.state.answers_list.key,
					step: undefined, // TODO: CHECK & DEBUG!
					report: undefined
				})
			})
		},

		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// TODO: DEPRECATED, will be removed in next PFH version
		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		async setProgress(store, progress) {
			// DEPRECATE TIMEOUT
			setTimeout(() => {
				store.commit('_setProgress', progress);
			}, 50)
		},

		async nuxtServerInit(store) {
			let session = store.rootState.session;
			let site = store.rootState.site;
			if (session.states_by_technology[site.technology_key] && session.states_by_technology[site.technology_key].answers_list_key) {
				return store.dispatch('loadAnswersList', session.states_by_technology[site.technology_key].answers_list_key)
			}
		},
		async loadQuestion(store, key) {
			let question = null;
			if (store.state.preloaded_question_promise && store.state.preloaded_question_promise.preloading_key === key) {
				question = await store.state.preloaded_question_promise;
			} else {
				question = await store.rootState.graph.models.Question.load_it(key);
			}
			store.commit('_setQuestion', question);
		},
		// TO DO: uncomment when needed (for current use cases preloading is working during AnswersList update (see next action)
		// async preloadQuestion(store, key) {
		//   if (store.state.preloaded_question_promise && store.state.preloaded_question_promise.preloading_key === key) {
		//     return store.state.preloaded_question_promise;
		//   }
		//   let preloaded_question_promise = store.rootState.graph.models.Question.load_it(key);
		//   preloaded_question_promise.preloading_key = key;
		//   store.commit('_setPreloadedQuestionPromise', preloaded_question_promise);
		//   return preloaded_question_promise;
		// },
		async updateAnswer(store, [question_key, answer]) {
			await store.commit('_setReportInfo', null);
			store.commit('_updateAnswer', [question_key, answer]);

			store.dispatch('tracker/trackEvent', {
				analytics: true,
				category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
				action: 'question_passed',
				label: question_key,
				is_interaction: true
			}, {root: true});

			let name = `quiz_${store.state.name || 'questionnaire'}_answer_chosen_${question_key}`;
			if (Array.isArray(answer) && answer.length === 0) {
				store.dispatch('tracker/trackEvent', {
					analytics: true,
					category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
					action: name,
					label: `${question_key}: Nothing selected`,
					is_interaction: true
				}, {root: true});
			}

			for (let answ_sn of Array.isArray(answer) ? answer : [answer]) {
				const question = store.state.question || store.rootState.site.first_question;
				let answ = question.answer_variants[answ_sn];
				let label = `${question_key}: ${(answ && (answ.title || (answ.attributes && answ.attributes.title) || answ.name || 'Untitled Answer')) || 'Nothing selected'}`;
				store.dispatch('tracker/trackEvent', {
					analytics: true,
					category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
					action: name,
					label: label,
					is_interaction: true
				}, {root: true});
			}

			let ret = await store.rootState.graph.models.AnswersList(store.state.answers_list).save_and_preload_next_question(store.state.answers_list.questions.indexOf(question_key) || 0);

			if (ret.next_question) {
				let preloaded_question_promise = Promise.resolve(ret.next_question);
				preloaded_question_promise.preloading_key = ret.next_question.key;
				store.commit('_setPreloadedQuestionPromise', preloaded_question_promise);
			}

			store.commit('_setAnswersList', ret.answers_list);
		},
		// async getIntermediateReportInfo(store) {
		//   let report_info = await store.rootState.graph.models.AnswersList(store.state.answers_list).get_intermediate_report_info();
		//   await store.commit('_setReportInfo', report_info);
		// },
		async getReportInfo(store) {
			let report_info = await store.rootState.graph.models.AnswersList(store.state.answers_list).get_report_info();

			// await store.dispatch('session/setTechnologyState', [store.state.answers_list.technology, store.state.answers_list.key, -1], {root: true});

			store.dispatch('tracker/trackEvent', {finished_answers_lists_keys: [store.state.answers_list.key]}, {root: true});

			let value = undefined;
			if (report_info.report) {
				// ADC Report value = avg_hours * hour_cost
				if (report_info.report.total && report_info.report.total.avg_hours && report_info.report.hour_cost) {
					value = report_info.report.total.avg_hours * report_info.report.hour_cost
				}
				// Readiness value = 100 * overall.value / overall.max
				if (report_info.report.measures && report_info.report.measures.overall
					&& report_info.report.measures.overall.max && report_info.report.measures.overall.value) {
					value = 100.0 * report_info.report.measures.overall.value / report_info.report.measures.overall.max
				}
			}

			store.dispatch('tracker/trackEvent', {
				analytics: true,
				category: 'quiz.' + (store.state.answers_list && (store.state.answers_list.article_key || store.state.answers_list.technology)),
				action: 'finish',
				label: 'Quiz Finished',
				value: value,
				is_interaction: true
			}, {root: true});

			await store.commit('_setReportInfo', report_info);
			// await store.dispatch('site/updateSiteState', {
			//   params: {
			//     answersListKey: store.state.answers_list.key,
			//     stepSn: -1
			//   }
			// }, {root: true});
		},
	},
})
;

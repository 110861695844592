import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cf6392e8 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _61f5d9a3 = () => interopDefault(import('../pages/dashboard/login.vue' /* webpackChunkName: "pages/dashboard/login" */))
const _48097f7f = () => interopDefault(import('../pages/dashboard/administrativeTools/manageProfile/index.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageProfile/index" */))
const _70ac1bde = () => interopDefault(import('../pages/dashboard/administrativeTools/manageUsers/index.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageUsers/index" */))
const _e476dd4a = () => interopDefault(import('../pages/dashboard/administrativeTools/manageVersions/index.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageVersions/index" */))
const _02cb0c80 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/api-samples.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/api-samples" */))
const _2f5260c3 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/articles.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/articles" */))
const _257b16b2 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/authors.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/authors" */))
const _8199449e = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/overall-w3c.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/overall-w3c" */))
const _38c090b7 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/questions.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/questions" */))
const _9ee43ce2 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/resources.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/resources" */))
const _dede28a0 = () => interopDefault(import('../pages/dashboard/administrativeTools/manageSites/_siteKey/technologies.vue' /* webpackChunkName: "pages/dashboard/administrativeTools/manageSites/_siteKey/technologies" */))
const _7d121dcb = () => interopDefault(import('../routing/dimension_frame.vue' /* webpackChunkName: "" */))
const _4141f6d4 = () => interopDefault(import('../routing/page_frame.vue' /* webpackChunkName: "" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
      path: "/dashboard",
      component: _cf6392e8,
      name: "dashboard"
    }, {
      path: "/dashboard/login",
      component: _61f5d9a3,
      name: "dashboard-login"
    }, {
      path: "/dashboard/administrativeTools/manageProfile",
      component: _48097f7f,
      name: "dashboard-administrativeTools-manageProfile"
    }, {
      path: "/dashboard/administrativeTools/manageUsers",
      component: _70ac1bde,
      name: "dashboard-administrativeTools-manageUsers"
    }, {
      path: "/dashboard/administrativeTools/manageVersions",
      component: _e476dd4a,
      name: "dashboard-administrativeTools-manageVersions"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/api-samples",
      component: _02cb0c80,
      name: "dashboard-administrativeTools-manageSites-siteKey-api-samples"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/articles",
      component: _2f5260c3,
      name: "dashboard-administrativeTools-manageSites-siteKey-articles"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/authors",
      component: _257b16b2,
      name: "dashboard-administrativeTools-manageSites-siteKey-authors"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/overall-w3c",
      component: _8199449e,
      name: "dashboard-administrativeTools-manageSites-siteKey-overall-w3c"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/questions",
      component: _38c090b7,
      name: "dashboard-administrativeTools-manageSites-siteKey-questions"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/resources",
      component: _9ee43ce2,
      name: "dashboard-administrativeTools-manageSites-siteKey-resources"
    }, {
      path: "/dashboard/administrativeTools/manageSites/:siteKey?/technologies",
      component: _dede28a0,
      name: "dashboard-administrativeTools-manageSites-siteKey-technologies"
    }, {
      path: "",
      component: _7d121dcb,
      meta: {"is_d0":true},
      children: [{
        path: "",
        component: _4141f6d4,
        meta: {"is_article_page":true},
        props: {"routingComponentName":"componentIndex"},
        name: "d0"
      }, {
        path: "questionnaire/start",
        component: _4141f6d4,
        meta: {"is_questionnaire_start_page":true},
        props: {"routingComponentName":"componentQuestionnaireStart"},
        name: "d0-questionnaire-start"
      }, {
        path: "questionnaire/:answersListKey?/step/:stepSn?",
        component: _4141f6d4,
        meta: {"is_questionnaire_step_page":true},
        props: {"routingComponentName":"componentQuestionnaireStep"},
        name: "d0-questionnaire-answersListKey-step-stepSn"
      }, {
        path: "questionnaire/:answersListKey?/report",
        component: _4141f6d4,
        meta: {"is_questionnaire_report_page":true},
        props: {"routingComponentName":"componentQuestionnaireReport"},
        name: "d0-questionnaire-answersListKey-report"
      }]
    }, {
      path: "/:pageCoord0",
      component: _7d121dcb,
      meta: {"is_d1":true},
      children: [{
        path: "",
        component: _4141f6d4,
        meta: {"is_article_page":true},
        props: {"routingComponentName":"componentIndex"},
        name: "d1"
      }, {
        path: "questionnaire/start",
        component: _4141f6d4,
        meta: {"is_questionnaire_start_page":true},
        props: {"routingComponentName":"componentQuestionnaireStart"},
        name: "d1-questionnaire-start"
      }, {
        path: "questionnaire/:answersListKey?/step/:stepSn?",
        component: _4141f6d4,
        meta: {"is_questionnaire_step_page":true},
        props: {"routingComponentName":"componentQuestionnaireStep"},
        name: "d1-questionnaire-answersListKey-step-stepSn"
      }, {
        path: "questionnaire/:answersListKey?/report",
        component: _4141f6d4,
        meta: {"is_questionnaire_report_page":true},
        props: {"routingComponentName":"componentQuestionnaireReport"},
        name: "d1-questionnaire-answersListKey-report"
      }]
    }, {
      path: "/:pageCoord0/:pageCoord1",
      component: _7d121dcb,
      meta: {"is_d2":true},
      children: [{
        path: "",
        component: _4141f6d4,
        meta: {"is_article_page":true},
        props: {"routingComponentName":"componentIndex"},
        name: "d2"
      }, {
        path: "questionnaire/start",
        component: _4141f6d4,
        meta: {"is_questionnaire_start_page":true},
        props: {"routingComponentName":"componentQuestionnaireStart"},
        name: "d2-questionnaire-start"
      }, {
        path: "questionnaire/:answersListKey?/step/:stepSn?",
        component: _4141f6d4,
        meta: {"is_questionnaire_step_page":true},
        props: {"routingComponentName":"componentQuestionnaireStep"},
        name: "d2-questionnaire-answersListKey-step-stepSn"
      }, {
        path: "questionnaire/:answersListKey?/report",
        component: _4141f6d4,
        meta: {"is_questionnaire_report_page":true},
        props: {"routingComponentName":"componentQuestionnaireReport"},
        name: "d2-questionnaire-answersListKey-report"
      }]
    }, {
      path: "/:pageCoord0/:pageCoord1/:pageCoord2",
      component: _7d121dcb,
      meta: {"is_d3":true},
      children: [{
        path: "",
        component: _4141f6d4,
        meta: {"is_article_page":true},
        props: {"routingComponentName":"componentIndex"},
        name: "d3"
      }]
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}

import Vue from 'vue';

Vue.mixin({
  computed: {
    window() {
      // if (process.server) { // TODO: this code occurs after SSR, so no use of it..
      //   return {console: console}
      // }
      // if (process.client) {
        return window || {};
      // }
      // return {};
      // throw new Error('Unexpected process type!');
    }
  }
});
import dayjs from 'dayjs';
// TODO: the only thing kept at cookies. Use it with care! only some places should address Session.
// TODO: Consider using Site store instead.

export default () => ({
	namespaced: true,
	state: () => ({
		user_key: null,
		states_by_technology: {},
		utm_tail: null,
		cookies_policy_hidden_timestamp_sec: 0,
		is_cookies_policy_hidden: true,
		is_loaded: false
	}),
	mutations: {
		_setState(state, new_state) {
			// let utm_tail = state.utm_tail; // Keep UTM tail (set on server side, during SSR)
			Object.assign(state, new_state);
			// state.utm_tail = utm_tail;
			state.is_loaded = true;
		},
		_setUserKey(state, user_key) {
			state.user_key = user_key;
		},
		_setUtmTail(state, utm_tail) {
			state.utm_tail = utm_tail;
		},
		_setTechnologyState(state, [technology, answers_list_key, step_sn]) {
			state.states_by_technology[technology] = {
				answers_list_key,
				step_sn
			};
		},
		_showCookiesPolicy(state) {
			state.is_cookies_policy_hidden = false;
		},
		_hideCookiesPolicy(state) {
			state.cookies_policy_hidden_timestamp_sec = dayjs().unix();
			state.is_cookies_policy_hidden = true;
		}
	},
	actions: {
		loadState(store) {
			let state = JSON.parse(window.localStorage.getItem('vuex'));
			state = (state && state.session) || JSON.parse(JSON.stringify(store.state));
			state.is_cookies_policy_hidden = (state.cookies_policy_hidden_timestamp_sec + (365 * 24 * 60 * 60)) > dayjs().unix(); // 365 days cookies bar readen expriation
			store.commit('_setState', state);
		},
		async init(store) {
			if (!process.client) return true;
			// await new Promise(r => setTimeout(r, 1000)); // TODO: Fix in next version: Session is being loaded too fast - SSR content should be equal to not-loaded session.
			await store.dispatch('loadState');
			if (window.location.search && window.location.search.length > 1) {
				store.commit('_setUtmTail', window.location.search.substr(1));
			}
			let session = store.rootState.session;
			let site = store.rootState.site;
			let user = await store.rootState.graph.models.User.account_user(site.technology_key, session.user_key, store.state.utm_tail);

			if (!store.state.user_key) {
				store.commit('_showCookiesPolicy');
			}
			store.commit('_setUserKey', user.key);
			store.commit('_setUser', user, {root: true});
			// if (session.states_by_technology[site.technology_key] && session.states_by_technology[site.technology_key].answers_list_key) {
			// 	await store.dispatch('questionnaire/loadAnswersList', session.states_by_technology[site.technology_key].answers_list_key, {root: true})
			// }
		},
		async setTechnologyState(store, [technology_key, answers_list_key, step_sn]) {
			store.commit('_setTechnologyState', [technology_key, answers_list_key, step_sn]);
		},
		async hideCookiesPolicy(store) {
			await store.rootState.graph.models.User(store.rootState.user).account_click('AGREED', 'COOKIES_POLICY', window.location.href, 'cookie_bar');
			store.commit('_hideCookiesPolicy');
		},
		async subscribeUser(store, visitor_info) {
			let user = await store.rootState.graph.models.User(store.rootState.user).subscribe(visitor_info);
			if (!user || !user.visitor_info){
				throw new Error(`Bad subscribing user response: ${JSON.stringify(user)}`);
			}
			store.commit('_setUser', user, {root: true});
		}
	},
});

<template>
  <div>
    <div v-if="!componentType">
      Error: component not found
    </div>
    <component v-else :is="componentType" :error="error">
    </component>
  </div>
</template>

<script>
  export default {
    layout(context) {
      return context.store.state.site.template
    },
    props: ['error'],
    async asyncData({res}) {
      res.statusCode = 404;
    },
    data() {
      let currentLayout = this.$root.$children[1];
      return {
        componentType: (currentLayout
          && currentLayout.components
          && currentLayout.components.component404)
        || null
      }
    },
    // head() {
    //   return {
    //     link: []
    //   }
    // }
  }
</script>

import Vue from 'vue';

export default () => ({
  namespaced: true,
  state: () => ({
    trackCallback: null,
  }),
  mutations: {
    _settrackCallback(state, trackCallback) {
      state.trackCallback = trackCallback;
    },
  },
  actions: {
    async init(store, init_event_info) {
      if (!process.client) return false;

      // Set not a bot
      let is_not_bot_set = false;
      let setNotBot = () => {
        if (is_not_bot_set) return;
        is_not_bot_set = true;
        window.document.removeEventListener('mousemove', setNotBot);
        window.document.removeEventListener('scroll', setNotBot);
        window.setTimeout(() => {
          store.rootState.graph.models.User(store.rootState.user).set_not_bot();
        }, 500);
      };
      window.document.addEventListener('mousemove', setNotBot);
      window.document.addEventListener('scroll', setNotBot);

      if (store.rootState.graph.config.ATTRIBUTION_TRACKER_URI) { // TODO: unlink from graph store
        // console.log('Initing the attribution tracker');
        window.attr_track_to_send = window.attr_track_to_send || [];
        window.account_event = function (data, cb) {
          window.attr_track_to_send.push([data, cb]);
        };

        store.commit('_settrackCallback', (event_info, callback) => {
          event_info = event_info || {};
          window.account_event(event_info, callback);
        });

        let script = window.document.createElement('script');
        script.src = store.rootState.graph.config.ATTRIBUTION_TRACKER_URI + "/tracker/account_visit_and_get_frontend_script";
        document.getElementsByTagName('head')[0].appendChild(script);

        if (init_event_info) {
          return store.dispatch('trackEvent', init_event_info);
        }
      }
    },
    async trackEvent(store, event_info) {
      if (!process.client) {
        return true;
      }
      // console.log('Event info', event_info);
      if (event_info.start_questionnaire_way) {
        // TODO: improve attribution tracker to support 'Once' events, i.e. account only first occurance with first value.
        // console.log('Accounting user start questionnaire way: ' + event_info.start_questionnaire_way + '..');
        await store.rootState.graph.session_init_promise;
        await store.rootState.graph.models.User(store.rootState.user).account_conversion_start_questionnaire_way(store.rootState.site.technology_key, event_info.start_questionnaire_way);
      }

      if (event_info.clicked_resources) {
        // TODO: improve performance by async running.
        // console.log('Accounting user click..');
        let resource = event_info.clicked_resources[0];
        if (resource && resource.name &&  resource.url && resource.place) {
          await store.rootState.graph.models.User(store.rootState.user).account_click(store.rootState.site.technology_key, resource.name, resource.url, resource.place);
        }
      }

      if (event_info.analytics && window.gtag && window.dataLayer) {
        let g_event_info = {'event_category' : 'pfh.' + store.rootState.site.site_key + (event_info.category ? '.' + event_info.category : ''),
          'event_label' : event_info.label,
          'non_interaction': !event_info.is_interaction
        };
        if (event_info.value !== undefined) {
          g_event_info.value = event_info.value;
        }
        // console.log('Sending gtag event:', g_event_info);
        window.gtag('event', event_info.action, g_event_info);
        window.dataLayer.push(Object.assign({action: event_info.action}, g_event_info));
        await new Promise(res => setTimeout(res, 1000));
      }

      if (!store.state.trackCallback) {
        return true;
      }
      event_info.site_key = store.rootState.site.site_key;
      return new Promise((resolve, reject) => {
        // let id = Math.random();
        let is_finished = false;
        store.state.trackCallback(event_info, () => {
          if (!is_finished) resolve();
          is_finished = true;
        });
        window.setTimeout(() => {
          // console.log('EVENT TIMEOUT', id, is_finished);
          if (!is_finished) resolve(); // No Reject - if Tracker is slow, than loose stats instead of crash.
          is_finished = true;
        }, 2000);
      })
    },
  },
});

import Vuex from 'vuex';
import Graph from './graph'
import Site from './site'
import Session from './session'
import Questionnaire from './questionnaire'
import Tracker from './tracker'

const createStore = () => {
  return new Vuex.Store({
    modules: {
      graph: Graph(),
      session: Session(),
      site: Site(),
      questionnaire: Questionnaire(),
      tracker: Tracker()
    },
    state: () => ({
      user: null,
    }),
    mutations: {
      _setUser(state, user) {
        state.user = user;
      },
    },
    actions: {
      async nuxtServerInit(store, context) {
        await store.dispatch('graph/nuxtServerInit', context);
        // await store.dispatch('graph/init');
        await store.dispatch('site/nuxtServerInit', context);
        await store.dispatch('questionnaire/nuxtServerInit');
      },
    }
  })
};

export default createStore;

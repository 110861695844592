<template>
	<a
		:href="resource.website + tail"
		:rel="should_robot_follow_filtered ? undefined : 'nofollow'"
		@click.stop.prevent="processClick"
		@contextmenu="accountClick"
	>
		<slot></slot>
	</a>
</template>
<script>
	export default {
		props: {
			resource: {
				type: Object,
				required: true
			},
			place: {
				type: String,
				required: true
			},
			additional_tail: {
				type: String,
				default: ''
			},
			should_robot_follow: {
				type: Boolean,
				required: false,
				default: false
			},
		},
		computed: {
			website() {
				return (this.resource.website || '').toLowerCase();
			},

			should_robot_follow_filtered() {
				return this.should_robot_follow
					&& this.website.indexOf('toptal.com') === -1
					&& this.website.indexOf('upwork.com') === -1
					&& this.website.indexOf('codementor.io') === -1
			},
			tail() {
				if (this.website.indexOf('theninehertz.com') !== -1) return '';

				let parts = [];
				if (this.$store.state.session.utm_tail) parts.push(this.$store.state.session.utm_tail);
				if (this.additional_tail) parts.push(this.additional_tail);

				let ret = parts.join('&');
				return ret ? ('?' + ret) : '';
			}
		},
		methods: {
			async accountClick() {
				// console.log('Accounting Outside link');
				await this.$store.dispatch('tracker/trackEvent', {
					'clicked_resources': [{
						name: this.resource.name,
						url: this.website + this.tail,
						place: this.place
					}],
				});
				await this.$store.dispatch('tracker/trackEvent', {
					analytics: true,
					category: 'external_links' + (this.place ? ('.' + this.place) : ''),
					action: 'click',
					label: (this.resource.name || '').toLowerCase(),
					is_interaction: true
				});
			},
			async processClick() {
				await this.accountClick();
				// console.log('Redirecting..');
				if (this.$attrs['target'] === '_blank') {
					window.open(this.website + this.tail)
				}
				else {
					window.location.href = this.website + this.tail;
				}
			}
		}
	}
</script>

import Vue from 'vue';

Vue.mixin({
	computed: {
		CUR_USER() {
			return this.$store.state.graph.auth && this.$store.state.graph.auth.user || null;
		},
		MODELS() {
			return this.$store.state.graph.models;
		},
		GRAPH_CONFIG() {
			return this.$store.state.graph.config;
		}
	}
});

// TODO: move GRAPH API frome store to here
export default async (context) => {
	// console.log('Start', Date.now());
	// console.log(Object.keys(context));
	if (process.client) {
		await context.store.dispatch('graph/init'); // Sync
		let session_promise = new Promise(resolve => {
			Vue.prototype.$markSessionInitiated = resolve;
		});
		await context.store.dispatch('graph/setSessionInitPromise', session_promise); // Sync

		session_promise // After ASync
			.then(() => { // no await
				return context.store.dispatch('tracker/init', {pfh_visitor_key: context.store.state.user && context.store.state.user.key});
			});
	}
}
